<template>
    <ContactTop />
    <router-view></router-view>
</template>

<script>
import ContactTop from '../../components/contact/ContactTop';
export default {
    name: 'Contact',
    components: {ContactTop}
}
</script>

<style lang="scss" scoped>

</style>