<template>
    <div class="banner">
        <img :src="imgUrl + banner" alt="" />
    </div>
    <!-- 导航 -->
    <div class="navigation_box">
        <div class="navigation max_small_box">
            <div :class="page_id == item.id ? 'list list_active' : 'list' " v-for="(item, index) in navigationList" :key="index" @click="handleNavIndex(index)">
                <router-link :to="`${item.page_id}/${item.toLink}/${item.id}${item.page_id}`">
                    <p class="name">{{item.name}}</p>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';


//轮播图下的导航处理函数
const navigationEffect = (route) => {

    //列表
    const navigationList = ref()

    //轮播图
    const banner = ref()

    const page_id = ref('')

    //更换index
    const handleNavIndex = () => {
        page_id.value = route.params.id
        // navigationIndex.value = index
    }

    //获取轮播图
    const getBanner = async () => {
        let postData = {
            page_id: '/news'
        }
        await post('/carousel/get_carousel', postData).then((res) => {
            // console.log('轮播图', res)
            if(res.code == 1) {
                banner.value = res.data.images
            }
        })
    }

    const getNav = async () => {
        let postData = {
            page_id: '/news'
        }
        await post('/nav/get_second_nav', postData).then((res) => {
            // console.log('新闻导航',res)
            if(res.code == 1) {
                navigationList.value = res.data
            }
        })
    }

    watchEffect(() => {
        handleNavIndex()
    })

    return { 
        navigationList, 
        handleNavIndex,
        getBanner,
        banner,
        getNav,
        page_id
    }
}
export default {
    name: 'NewsTop',
    props: ['activeIndex'],
    setup() {
        const route = useRoute()
        //轮播图下的导航处理函数
        const { 
            navigationList, 
            handleNavIndex ,
            getBanner,
            banner,
            getNav,
            page_id
        } = navigationEffect(route)

        getBanner()
        getNav()

        const { imgUrl }  = api

        page_id.value = route.params.id
        return { 
            imgUrl,
            navigationList,
            handleNavIndex,
            banner,
            page_id
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
/* 轮播图 */
.banner{
    margin-top: 111px;
    @media screen and (max-width: 1200px){
        margin-top: $phone-banner-marginTop;
        height: $phone-banner-height;
    }
    img{
        width: 100%;
        /* margin: 0 auto; */
        display: block;
        @media screen and (max-width: 1200px){
            height: 100%;
            object-fit: cover;
        }
    }
}
/* 导航 */
.navigation_box{
    background: rgba(255, 255, 255, .8);
    margin-top: -66px;
    position: relative;
    z-index: 9;
    @media screen and (max-width: 1200px){
        margin-top: 0px;
        position: relative;
        width: 100%;
    }
}
.navigation{
    display: flex;
    align-items: center;
    @media screen and (max-width: 1200px){
        display: block;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        font-size: 0;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }
    .list{
        width: 100%;
        text-align: center;
        transition: .3s all;
        &:hover{
            background: #2F509E;
            .name{
                color: #fff;
                font-weight: bold;
            }
        }
        @media screen and (max-width: 1200px){
            width: 33.3%;
            margin-bottom: 10px;
            text-align: center;
            transition: 0.3s all;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
        }
        .name{
            color: rgba(16, 16, 16, 100);
            font-size: 16px;
            border-right: 1px solid #bbb;
            padding: 25px 0;
            cursor: pointer;
            transition: .3s all;
            @media screen and (max-width: 900px){
                font-size: .14rem;
                padding: 18px 0;
            }
        }
    }
    .list_active{
        background: #2F509E;
        .name{
            color: #fff;
            font-weight: bold;
        }
    }
}
</style>