import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import 'normalize.css'
import './style/base.scss'
import './style/iconfont.css'

//页面滚动动画
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init();

// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style
import 'swiper/swiper-bundle.css'
createApp(App).use(VueAwesomeSwiper)


createApp(App).use(store).use(router).use(Antd).mount('#app')
