import { createRouter, createWebHashHistory } from 'vue-router'
import About from '../views/about/About'
import News from '../views/news/News'
import Resources from '../views/resources/Resources'
import Contact from '../views/contact/Contact'

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Home')
  },
  // 公正性声明
  {
    path: '/statement',
    name: 'Statement',
    component: () => import(/* webpackChunkName: "Statement" */ '../views/home/Statement')
  },
  // 收费标准
  {
    path: '/toll',
    name: 'Toll',
    component: () => import(/* webpackChunkName: "Toll" */ '../views/home/Toll')
  },
  // 收费标准
  {
    path: '/toll_second',
    name: 'TollSecond',
    component: () => import(/* webpackChunkName: "Toll" */ '../views/home/TollSecond')
  },
  // 加入我们
  {
    path: '/join',
    name: 'Join',
    component: () => import(/* webpackChunkName: "Join" */ '../views/home/Join')
  },
  // 加入我们详情
  {
    path: '/join_detail/:id',
    name: 'JoinDetail',
    component: () => import(/* webpackChunkName: "JoinDetail" */ '../views/home/JoinDetail')
  },
  // 查询报告详情
  {
    path: '/reoprt_detail/:id?',
    name: 'ReoprtDetail',
    component: () => import(/* webpackChunkName: "ReoprtDetail" */ '../views/home/ReoprtDetail')
  },
  // 行业
  {
    path: '/industry/:id/:page_id/:second_id/:index',
    name: 'Industry',
    component: () => import(/* webpackChunkName: "industry" */ '../views/industry/Industry')
  },
  // 行业列表
  {
    path: '/industry_list/:second_id/:index/:indextwo?',
    name: 'IndustryList',
    component: () => import(/* webpackChunkName: "IndustryList" */ '../views/industry/IndustryList')
  },
  // 服务
  {
    path: '/service/:id',
    name: 'Service',
    component: () => import(/* webpackChunkName: "service" */ '../views/service/Service')
  },
  // 服务详情
  {
    path: '/serviceDetail/:id',
    name: 'ServiceDetail',
    component: () => import(/* webpackChunkName: "serviceDetail" */ '../views/serviceDetail/ServiceDetail')
  },
  //搜索页面
  {
    path: '/search/:id?',
    name: 'Search',
    component: () => import(/* webpackChunkName: "Search" */ '../views/search/Search')
  },
  // 关于我们
  {
    path: '/about',
    name: 'About',
    component: About,
    children: [
      // 中心简介
      {
        path: 'center/:id/:page_id',
        name: 'AboutCenter',
        component: () => import(/* webpackChunkName: "aboutCenter" */ '../views/about/Center')
      },
      // 国检中心简介
      {
        path: 'nationalInspection/:id/:page_id',
        name: 'AboutNationalInspection',
        component: () => import(/* webpackChunkName: "NationalInspection" */ '../views/about/NationalInspection')
      },
      // 国检中心简介详情
      {
        path: 'nationalInspection_detail/:id/:page',
        name: 'NationalInspectionDetail',
        component: () => import(/* webpackChunkName: "nationalInspectionDetail" */ '../views/about/NationalInspectionDetail')
      },
      // 国检中心简介详情pdf
      {
        path: 'nationalInspectionDetailPdf/:id',
        name: 'nationalInspectionDetailPdf',
        component: () => import(/* webpackChunkName: "nationalInspectionDetailPdf" */ '../views/about/NationalInspectionDetailPdf')
      },
      // 省站简介
      {
        path: 'provincialStation/:id/:page_id',
        name: 'ProvincialStation',
        component: () => import(/* webpackChunkName: "ProvincialStation" */ '../views/about/ProvincialStation')
      },
      // 荣誉资质
      {
        path: 'honorQualification/:id/:page_id',
        name: 'HonorQualification',
        component: () => import(/* webpackChunkName: "HonorQualification" */ '../views/about/HonorQualification')
      },
      // 资质
      {
        path: 'qualification/:id/:page_id',
        name: 'Qualification',
        component: () => import(/* webpackChunkName: "Qualification" */ '../views/about/Qualification')
      },
      // 荣誉
      {
        path: 'honor/:id/:page_id',
        name: 'Honor',
        component: () => import(/* webpackChunkName: "Honor" */ '../views/about/Honor')
      },
      // 经典案例
      {
        path: 'case/:id/:page_id',
        name: 'Case',
        component: () => import(/* webpackChunkName: "Case" */ '../views/about/Case')
      },
      // 经典案例详情
      {
        path: 'case_detail/:id',
        name: 'CaseDetail',
        component: () => import(/* webpackChunkName: "CaseDetail" */ '../views/about/CaseDetail')
      },
    ]
  },
  // 新闻动态
  {
    path: '/news',
    name: 'News',
    component: News,
    children: [
      // 中心动态
      {
        path: 'newsCenter/:id/:page_id',
        name: 'NewsCenter',
        component: () => import(/* webpackChunkName: "NewsCenter" */ '../views/news/NewsCenter'),
      },
      {
        path: 'news_search/:id',
        name: 'NewsSearch',
        component: () => import(/* webpackChunkName: "NewsSearch" */ '../views/news/NewsSearch'),
      },
      //详情
      {
        path: 'news_detail/:id',
        name: 'NewsDetail',
        component: () => import(/* webpackChunkName: "NewsDetail" */ '../views/news/NewsDetail'),
      },
      // 特色专栏
      {
        path: 'newsFeature',
        name: 'NewsFeature',
        component: () => import(/* webpackChunkName: "newsFeature" */ '../views/news/NewsFeature')
      },
      // 行业资讯
      {
        path: 'newsIndustry',
        name: 'NewsIndustry',
        component: () => import(/* webpackChunkName: "NewsIndustry" */ '../views/news/NewsIndustry')
      },
      // 标准法规
      {
        path: 'newsStandard',
        name: 'NewsStandard',
        component: () => import(/* webpackChunkName: "NewsStandard" */ '../views/news/NewsStandard')
      },
      //最新公告
      {
        path: 'newsProclamation',
        name: 'NewsProclamation',
        component: () => import(/* webpackChunkName: "NewsProclamation" */ '../views/news/NewsProclamation')
      },
    ]
  },
  // 资源中心
  {
    path: '/resource',
    name: 'Resources',
    component: Resources,
    children: [
      // 宣传手册
      {
        path: 'resourcesPropaganda/:id/:page_id',
        name: 'ResourcesPropaganda',
        component: () => import(/* webpackChunkName: "ResourcesPropaganda" */ '../views/resources/ResourcesPropaganda')
      },
      //宣传视频
      {
        path: 'resourcesVideo/:id/:page_id',
        name: 'ResourcesVideo',
        component: () => import(/* webpackChunkName: "ResourcesVideo" */ '../views/resources/ResourcesVideo')
      },
      //教育基地
      {
        path: 'resourcesEducation/:id/:page_id',
        name: 'ResourcesEducation',
        component: () => import(/* webpackChunkName: "ResourcesEducation" */ '../views/resources/ResourcesEducation')
      },
      //教育基地详情
      {
        path: 'resources_education_detail/:id',
        name: 'ResourcesEducationDetail',
        component: () => import(/* webpackChunkName: "ResourcesEducationDetail" */ '../views/resources/ResourcesEducationDetail')
      },
    ]
  },
  // 联系我们
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    children: [
      //联系信息
      {
        path: 'contactUs/:id/:page_id',
        name: 'ContactUs',
        component: () => import(/* webpackChunkName: "ContactUs" */ '../views/contact/ContactUs')
      },
      //微信公众号
      {
        path: 'contactWechat/:id/:page_id',
        name: 'ContactWechat',
        component: () => import(/* webpackChunkName: "ContactWechat" */ '../views/contact/ContactWechat')
      },
      //留言建议
      {
        path: 'contactMessage/:id/:page_id',
        name: 'ContactMessage',
        component: () => import(/* webpackChunkName: "ContactMessage" */ '../views/contact/ContactMessage')
      },
      //廉政投诉
      {
        path: 'contactComplaint/:id/:page_id',
        name: 'ContactComplaint',
        component: () => import(/* webpackChunkName: "ContactComplaint" */ '../views/contact/ContactComplaint')
      },
      //满意都测评
      {
        path: 'contactEvaluation/:id/:page_id',
        name: 'ContactEvaluation',
        component: () => import(/* webpackChunkName: "ContactEvaluation" */ '../views/contact/ContactEvaluation')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})


export default router
