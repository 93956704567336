<template>
    <div class="box">
        <div class="small_box">
            <div class="item" id="kefu_button" @click="changeModal">
                <img src="@/assets/images/farfa-comment-dots@1x.png" alt="" />
                <p class="title">在线咨询</p>
                <div class="line"></div>
            </div>
            <router-link class="item" :to="`/contact/contactMessage/19/contact`">
                <img src="@/assets/images/md-insert_comment@1x.png" alt="" />
                <p class="title">留言建议</p>
                <div class="line"></div>
            </router-link>
            <router-link class="item" :to="`/contact/contactComplaint/20/contact`">
                <img src="@/assets/images/antOutline-alert@1x.png" alt="" />
                <p class="title title_red">举报投诉</p>
                <div class="line"></div>
            </router-link>
            <router-link class="item" :to="`/reoprt_detail`">
                <img src="@/assets/images/fasfa-search@1x.png" alt="" />
                <p class="title">查询报告</p>
            </router-link>
        </div>
    </div>
    <div class="box_two box" :style="{ right: number+'px'}">
        <div class="small_box small_box_two">
            <div class="item" id="kefu_button" @click="changeModal('show')">
                <img src="@/assets/images/farfa-comment-dots@1x.png" alt="" />
                <p class="title">在线咨询</p>
                <div class="line"></div>
            </div>
            <router-link class="item" :to="`/contact/contactMessage/19/contact`" @click="changeShow">
                <img src="@/assets/images/md-insert_comment@1x.png" alt="" />
                <p class="title">留言建议</p>
                <div class="line"></div>
            </router-link>
            <router-link class="item" :to="`/contact/contactComplaint/20/contact`" @click="changeShow">
                <img src="@/assets/images/antOutline-alert@1x.png" alt="" />
                <p class="title title_red">举报投诉</p>
                <div class="line"></div>
            </router-link>
            <router-link class="item" :to="`/reoprt_detail`" @click="changeShow">
                <img src="@/assets/images/fasfa-search@1x.png" alt="" />
                <p class="title">查询报告</p>
            </router-link>
            <div class="shrink" @click="changeShow">
                <!-- <p class="iconfont">&#xe60c;</p> -->
                <img class="icon_img" src="@/assets/images/icon02.png" alt="">
            </div>
        </div>
    </div>
    <!-- <Customer /> -->
    
</template>

<script>
import { ref } from 'vue'
import Customer from '../customer/Customer'

//侧边栏是否显示
const show = () => {

    const number = ref(-90)

    const is_show = ref(false)

    const  changeShow = () => {
        is_show.value = !is_show.value
        if(is_show.value == true){
            number.value = 0
        }else{
            number.value = -90
        }
    }
    const changeModal = (name) =>{
        const newWindow = window.open('', '_blank', 'width=600,height=400');
        newWindow.document.write(`
            <html>
            <body style="margin:0;padding:0;text-align: center;">
                <p style="text-align: center;">扫码添加微信客服咨询</p>
                <img src="https://gddqt.com//uploads/20240910/045192d6720e118f6ae0c785c4e20c96.png" alt="Your Image" style="width:90%;height:90%;object-fit:contain;" />
            </body>
            </html>
        `);
        if(typeof name==='string'){
            is_show.value = !is_show.value
            if(is_show.value == true){
                number.value = 0
            }else{
                number.value = -90
            }
        }
    }

    return{ number, is_show, changeShow, changeModal }

}

export default {
    name: 'FloatingFrame',
    components: {Customer},
    setup() {
    
        const { number, is_show, changeShow, changeModal } = show()

        return { number, is_show, changeShow, changeModal }

    }

}
</script>

<style lang="scss" scoped>
.main{
	height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.box{
    width: 64px;
    position: fixed;
    top: 30%;
    right: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    z-index: 10;
    /* padding: 0 .5%; */
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 20%);
    @media screen and (max-width: 900px){
        display: none;
    }
    .small_box{
        position: relative;
        
        .item{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
            &:last-child{
                margin-bottom: 15px;
            }
            img{
                width: 25px;
                height: 25px;
            }
            .title{
                color: #333;
                font-size: 12px;
                margin-top: 10px;
                text-align: center;
                /* width: 38px; */
                line-height: 19px;
            }
            .title_red{
                color: #FF0000;
            }
            .line{
                width: 50%;
                height: 1px;
                background-color: #BBBBBB;
                margin-top: 10px;
            }
        }
        .shrink{
            position: absolute;
            top: 40%;
            left: -60%;
            background: #fff;
            padding: 10px;
            box-shadow: -6px 2px 7px #2727275e;
            p{
                font-size: 30px;
            }
        }
    }
}
.box_two{
    width: 80px;
    position: fixed;
    top: 30%;
    right: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    z-index: 9;
    padding: 0 .5%;
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 20%);
    display: none;
    transition: .3s all;
    @media screen and (max-width: 900px){
        display: block;
    }
    .icon_img{
        width: 30px;
        height: 30px;
    }
}
</style>