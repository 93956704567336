<template>
    <AboutTop />
    <router-view ></router-view>
</template>

<script>
import AboutTop from '../../components/about/AboutTop';


export default {
    name: 'Contact',
    components: {AboutTop},
}
</script>

<style lang="scss" scoped>

</style>