<template>
    <NewsTop :activeIndex="0" />
    <router-view ></router-view>
</template>

<script>
import NewsTop from '../../components/news/NewsTop';
export default {
    name: 'News',
    components: {NewsTop}
}
</script>

<style lang="scss" scoped>

</style>