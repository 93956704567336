<template>
    
</template>

<script>
export default {
    name: 'Customer',
    setup() {
        // KeFu.initialize('gddqt.com', 'index', function () {
        KeFu.initialize(window.location.host, 'index', function () {
            // KeFu.toggle_window('show');// 打开窗口
        });   
    }
}
</script>

<style lang="scss" scoped>

</style>