<template>
    <ResourcesTop />
    <router-view></router-view>
</template>

<script>
import ResourcesTop from '../../components/resources/ResourcesTop';
export default {
    name: 'Resources',
    components: {ResourcesTop}
}
</script>

<style lang="scss" scoped>

</style>