<template>
  <!-- <router-view :key="$route.fullPath"/> -->
  <router-view  />
  <Footer v-if="routePath!='/toll_second'" />
  <FloatingFrame v-if="routePath!='/toll_second'" />
</template>

<script>
import { ref, watch } from 'vue'
import { delCookie } from './utils/cookie'
import { useRoute } from 'vue-router'
import Footer from './components/footer/Footer';
import FloatingFrame from './components/floatingFrame/FloatingFrame';

const clearEffect = () => {
    const clearCookies = () => {
      var cookies = document.cookie.split(";");
      var domain = '.'+location.host;
      // console.log('cookies', cookies);
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain="+domain+"; path=/";
      }
      if(cookies.length > 0){
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain="+domain+"; path=/";
        }
      }
    }
    return { clearCookies }
}

export default {
  name: 'App',
  components: { Footer, FloatingFrame},
  created(){
  },
  setup() {
    const { clearCookies } = clearEffect()

    delCookie('kefu_user')
    clearCookies()
    localStorage.clear()
    sessionStorage.clear()
    
    // 获取路径
    const routePath = ref()
    const route = useRoute()
    watch(() => route.path,newRoute=> {
      routePath.value = newRoute
    })
    return{ routePath }
  }
}
</script>

<style lang="scss">

                    
  .ant-table-thead > tr > th{
      background: #2F509E!important;
      color: #fff!important;
  }
</style>
