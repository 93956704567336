<template>
    <div class="footer_box">
        <div class="footer max_small_box">
            <div class="left">
                <p class="big">广东省东莞市质量监督检测中心</p>
                <p v-for="(item, index) in detail" :key="index">{{item.title}}{{item.intro}}</p>
            </div>
            <div class="right">
                <div class="item" v-if="list">
                    <p class="title">行业</p>
                    <router-link class="desc" :to="`/industry/${item.id}${list[0].page_id}/${list[0].id}/${index}`" v-for="(item, index) in list[0].third" :key="index">
                        <p>{{item.name}}</p>
                    </router-link>
                </div>
                <div class="itemtwo" v-if="list">
                    <p class="title">服务</p>
                    <router-link class="desc" :to="`/industry/${item.id}${list[1].page_id}/${list[1].id}/${index}`" v-for="(item, index) in list[1].third" :key="index">
                        <p>{{item.name}}</p>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="link_box max_small_box">
            <p style="color:#000; font-size:16px">友情链接:</p>
            <div class="link">
                <a v-for="(item, index) in fourArr" :key="index" :href="item.link" target="_blank" >{{item.name}}</a>
                <!-- <a href="http://www.samr.gov.cn/" target="_blank" >国家市场监督管理总局</a>
                <a href="http://dgamr.dg.gov.cn/" target="_blank" >东莞市市场监督管理局</a>
                <a href="http://www.dqtpaper.com" target="_blank" >国家纸制品质量检验检测中心</a>
                <a href="http://www.dqtmould.com" target="_blank" >国家模具产品质量检验检测中心（广东）</a>
                <a href="http://www.dqtled.com" target="_blank" >国家半导体光源产品质量检验检测中心（广东）</a>
                <a href="http://www.dqtit.com" target="_blank" >国家信息技术设备质量检验检测中心</a>
                <a href="http://www.dqtime.org.cn" target="_blank" >国家智能加工装备质量检验检测中心（广东）</a> -->
                 <select v-model="one" @change="changeProduct($event)">
                        <option value="#" selected="">-请选择全国市场监管系统-</option>
                        <option v-for="(item, index) in oneArr" :key="index" :value="item.link" >{{item.name}}</option>
                        <!-- <option value="http://www.samr.gov.cn/" >国家市场监督管理总局</option>

                        <option value="http://amr.hlj.gov.cn/">黑龙江省市场监督管理局</option>

                        <option value="http://scjg.jl.gov.cn">吉林省市场监督管理厅</option>

                        <option value="http://scjg.ln.gov.cn/">辽宁省市场监督管理局</option>

                        <option value="http://www.qhaic.gov.cn/index.htm">青海省市场监督管理局</option>

                        <option value="http://scjg.gansu.gov.cn/">甘肃省市场监督管理局</option>

                        <option value="http://scjgj.shanxi.gov.cn/index.htm">山西省市场监督管理局</option>

                        <option value="http://scjg.hebei.gov.cn">河北省市场监督管理局</option>

                        <option value="http://amr.shandong.gov.cn/">山东省市场监督管理局</option>

                        <option value="http://snamr.shaanxi.gov.cn/">陕西省市场监督管理局</option>

                        <option value="http://www.haaic.gov.cn">河南省市场监督管理局</option>

                        <option value="http:////scjgj.sc.gov.cn">四川省市场监督管理局</option>

                        <option value="http://scjg.hubei.gov.cn">湖北省市场监督管理局</option>

                        <option value="http://amr.ah.gov.cn">安徽省市场监督管理局</option>

                        <option value="http://scjgj.jiangsu.gov.cn/">江苏省市场监督管理局</option>

                        <option value="http://zjamr.zj.gov.cn/">浙江省市场监督管理局</option>

                        <option value="http://amr.hainan.gov.cn">海南省市场监督管理局</option>

                        <option value="http://amr.jiangxi.gov.cn">江西省市场监督管理局</option>

                        <option value="http://amr.hunan.gov.cn">湖南省市场监督管理局</option>

                        <option value="http://amr.guizhou.gov.cn">贵州省市场监督管理局</option>

                        <option value="http://ynamr.ynaic.gov.cn/ecdomain/framework/ynaic/index.jsp">云南省市场监督管理局</option>

                        <option value="http://zjj.fujian.gov.cn">福建省市场监督管理局</option>

                        <option value="http://gdamr.gdgs.gov.cn">广东省市场监督管理局</option>

                        <option value="http://www.gxqts.gov.cn">广西自治区市场监督管理局</option>

                        <option value="http://nmamr.nmgs.gov.cn">内蒙古自治区市场监督管理局</option>

                        <option value="http://www.xjzj.gov.cn">新疆维吾尔自治区市场监督管理局</option>

                        <option value="http://www.xzaic.gov.cn/index.htm">西藏自治区市场监督管理局</option>

                        <option value="http://www.ngsh.gov.cn">宁夏回族自治区市场监督管理厅</option>

                        <option value="http://scjgj.beijing.gov.cn">北京市市场监督管理局</option>

                        <option value="http://www.sgs.gov.cn/shaic/index_new.html">上海市市场监督管理局</option>

                        <option value="http://scjg.tj.gov.cn">天津市市场监督管理委员会</option>

                        <option value="http://scjgj.cq.gov.cn">重庆市市场监督管理局</option> -->
                    </select>

                    <select v-model="two" @change="changeProduct($event)">
                        <option value="#" selected="">-请选择广东省市场监管系统-</option>

                        <option v-for="(item, index) in twoArr" :key="index" :value="item.link" >{{item.name}}</option>
                        <!-- <option value="http://scjgj.gz.gov.cn/">广州市市场监督管理局</option>

                        <option value="http://amr.sz.gov.cn/">深圳市市场监督管理局</option>

                        <option value="http://dgamr.dg.gov.cn/">东莞市市场监督管理局</option>

                        <option value="http://www.jieyang.gov.cn/jyamr">揭阳市市场监督管理局</option>

                        <option value="http://www.yangjiang.gov.cn/yjscjd/gkmlpt/index">阳江市市场监督管理局</option>

                        <option value="https://www.meizhou.gov.cn/zwgk/zfjg/sscjdglj/">梅州市市场监督管理局</option>

                        <option value="http://www.chaozhou.gov.cn/zwgk/szfgz/sjdglj/index.html">潮州市市场监督管理局</option>

                        <option value="http://www.heyuan.gov.cn/bmjy/hysscjdglj/gzdt/">河源市市场监督管理局</option>

                        <option value="http://scj.maoming.gov.cn/">茂名市市场监督管理局</option>

                        <option value="http://zjamr.zhanjiang.gov.cn/">湛江市市场监督管理局</option>

                        <option value="http://www.gdqy.gov.cn/channel/qysscjdglj/">清远市市场监督管理局</option>

                        <option value="http://www.jiangmen.gov.cn/bmpd/jmsscjdglj/">江门市市场监督管理局</option>

                        <option value="http://www.zhaoqing.gov.cn/zqscjgj/gkmlpt/index">肇庆市市场监督管理局</option>

                        <option value="http://www.zs.gov.cn/zjj/">中山市市场监督管理局</option>

                        <option value="http://fsamr.foshan.gov.cn">佛山市市场监督管理局</option>

                        <option value="http://www.shanwei.gov.cn/swscjdglj/">汕尾市市场监督管理局</option>

                        <option value="https://www.shantou.gov.cn/scjgj/">汕头市市场监督管理局</option>

                        <option value="http://hzamr.huizhou.gov.cn/">惠州市市场监督管理局</option>

                        <option value="https://www.sg.gov.cn/bmpdlm/sgsscjdglj/index.html">韶关市市场监督管理局</option>

                        <option value="http://www.gdzhaic.gov.cn">珠海市市场监督管理局</option>

                        <option value="http://zl.yunfu.gov.cn/yfgs/">云浮市市场监督管理局</option> -->

                    </select>

                    <select v-model="three" @change="changeProduct($event)">
                        <option value="#" selected="">-请选择友情链接站点-</option>

                        <option v-for="(item, index) in threeArr" :key="index" :value="item.link" >{{item.name}}</option>
                        <!-- <option value="http://www.zhwcn.com">质汇网</option>

                        <option value="http://dgzl.org.cn">东莞市质量协会</option>

                        <option value="http://www.dqtime.org.cn/">国家智能加工装备监督检验中心（广东）</option>

                        <option value="http://www.dqtmould.com/index.asp">国家模具产品质量监督检验中心（广东）</option>

                        <option value="http://www.dqtit.com">国家信息技术设备质量监督检验中心</option>

                        <option value="http://www.dqtled.com">国家半导体光源产品质量监督检验中心（广东）</option>

                        <option value="http://www.dqtpaper.com">国家纸制品质量监督检验中心</option> -->

                    </select>
                <div class="select_box">
                   
                </div>
            </div>
        </div>
        <!-- <div class="foot_borrom ">
            <div class="max_small_box text">
                <a href="https://beian.miit.gov.cn" target="_blank" class="">粤ICP备11067593号-5 版权所有:广东省东莞市质量监督检测中心</a>
                <img class="img" src="@/assets/images/blue.png" />
            </div>
        </div> -->
    </div>
</template>
<script>
import { reactive, ref } from 'vue'
import { useRouter } from "vue-router";
import { post, get } from '../../utils/request';

const footerEffect = () => {
    const list = ref()
    
    const one = ref('#')
    const oneArr = ref([])

    const two = ref('#')
    const twoArr = ref([])

    const three = ref('#')
    const threeArr = ref([])
    const fourArr = ref([])

    const detail = ref()

    const getServiceNav = async () => {
        try{
            await post('/nav/get_second_third_nav', {page_id: '/service_industry'}).then((res) => {
                list.value = res.data
            })
        }catch(e) {
        
        }
    }

    const getFootDetail = async () => {
        try{
            await get('/index/get_footer', {}).then((res) => {
                detail.value = res.data.content
            })
        }catch(e) {
        }
    }

    const getFootLink = async () => {
        try{
            await get('/index/get_friendLink', {}).then((res) => {
                oneArr.value = res.data.first
                twoArr.value = res.data.second
                threeArr.value = res.data.third
                fourArr.value = res.data.four
            })
        }catch(e) {
        }
    }

    return { list, getServiceNav, one, two, three, oneArr, twoArr, threeArr, fourArr, getFootDetail, getFootLink, detail }
}

export default {
    name: 'Footer',
    components: {
        'scriptLink': {
            render(createElement) {
                return createElement(
                'script',
                {
                    attrs: {
                        type: 'text/javascript',
                        src: '//g.alicdn.com/sd/smartCaptcha/0.0.1/index.js',
                    },
                },
                )
            }
        }
    },
    setup() {
        const { list, getServiceNav, one, two, three, oneArr, twoArr, threeArr, fourArr, getFootDetail, getFootLink, detail } = footerEffect()

        getServiceNav()
        getFootDetail()
        getFootLink()

        const changeProduct = (e) => {
            window.open(e.target.value);
            // console.log(e.target.value)
        }

        return { list, one, two, three, oneArr, twoArr, threeArr, fourArr, changeProduct, detail }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
.footer_box{
    background-color: #fff;
    border-top: 1px solid rgba(187, 187, 187, 100);
    margin-top: 30px;
    .footer{
        display: flex;
        padding-top: 50px;
        @media screen and (max-width: 1200px){
            padding-left: 30px;
            padding-right: 30px;
        }
        .left{
            width: 40%;
            margin-right: 2%;
            @media screen and (max-width: 900px){
                width: 100%;
                margin-right: 0%;
            }
            p{
                color: $all-desc-color;
                font-size: 14px;
                margin-bottom: 10px;
                line-height: 24px;
                &:last-child{
                    margin-bottom: 0px;
                }
                @media screen and (max-width: 900px){
                    font-size: .14rem;
                }
            }
            .big{
                color: #333;
                font-size: 20px;
                margin-bottom: 24px;
                @media screen and (max-width: 900px){
                    font-size: .16rem;
                }
            }
        }
        .right{
            width: 56%;
            margin-left: 2%;
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 900px){
                width: 100%;
                margin-left: 0%;
                display: none;
            }
            .item{
                width: 100%;
                .title{
                    color: #333;
                    font-size: 20px;
                    /* font-weight: bold; */
                    margin-bottom: 24px;
                }
                .desc{
                    color: $all-desc-color;
                    font-size: 14px;
                    margin-bottom: 10px;
                    margin-right: 20px;
                    width: 27%;
                    float: left;
                    line-height: 24px;    
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    &:last-child{
                        margin-bottom: 0px;
                    }
                    &:hover{
                        color: $content-backgroundcolor;
                    }
                }
            }
            .itemtwo{
                width: 100%;
                .title{
                    color: #333;
                    font-size: 20px;
                    /* font-weight: bold; */
                    margin-bottom: 24px;
                }
                .desc{
                    color: $all-desc-color;
                    font-size: 14px;
                    margin-bottom: 10px;
                    margin-right: 20px;
                    width: 35%;
                    float: left;
                    line-height: 24px;    
                    /* overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical; */
                    &:last-child{
                        margin-bottom: 0px;
                    }
                    &:hover{
                        color: $content-backgroundcolor;
                    }
                }
            }
        }
    }
    .link_box{
        padding-bottom: 50px;
        @media screen and (max-width: 1200px){
            padding-left: 30px;
            padding-right: 30px;
        }
        @media screen and (max-width: 900px){
            margin-top: 15px;
        }
        .link{
            margin-top: 15px;
            display: flex;
            flex-wrap: wrap;
            a{
                color: #989898;
                font-size: 14px;
                line-height: 1.8;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 5px;
                margin-bottom: 15px;
                width: calc(100% / 5.5);
                @media screen and (max-width: 900px){
                    display: block;
                    margin-right: 0;
                    width: 100%;
                }
                &:hover{
                    color: $content-backgroundcolor;
                }
            }
            select{
                outline: none;
                border: 1px solid #bbb;
                padding: 0 5px;
                font-size: 14px;
                color: #868686;
                width: calc(100% / 5.5);
                margin-right: 5px;
                margin-bottom: 15px;
                @media screen and (max-width: 900px){
                    margin-top: 15px;
                    display: block;
                    margin-right: 0;
                    width: 100%;
                }
            }
        }
    }
    
    .foot_borrom{
        background-color:#F8F8F8;
        
        .text{
            display: flex;
            align-items: center;
            @media screen and (max-width: 1200px){
                padding: 0 30px;
            }
            a{
                color: hsl(0,0%,60%);
                line-height: 44px;
                font-size: 12px;
                display: block;
                @media screen and (max-width: 900px){
                line-height: 22px;
                }
            }
            .img{
                width: 20px;
                margin-left: 15px;
            }
        }
        
    }
}
</style>